import React from "react";
import { Layout } from "../../components/Layout";
import { Head } from "../../components/Head";
import { CaseStudiesTitle } from "../../components/CaseStudiesTitle";
import { CaseStudiesText } from "../../components/CaseStudiesText";

export default function CS_Internal() {
  const title = "Internal medicine";
  const subTitle = "内科";
  const overview = [
    "内科は診療所の６割以上が標榜しているメジャーな診療科目です",
    <br />,
    "一般内科のみの場合、大都市エリアだけではなく郊外エリアも視野に入れる必要があります",
  ];
  const openingCash = "おおよその開業資金";
  const openingCashText = [
    "土地、建物　7,000万円～15,000万円",
    <br />,
    "設備　2,000万円〜3,500万円",
    <br />,
    "電子カルテ、レジスター、コピー複合機、診察用ベッド、X線撮影装置、",
    <br />,
    "DICOM（ダイコム）画像を見られる高精細モニターとPACS、超音波診断装置、心電計、内視鏡、内視鏡洗浄機",
  ];
  const point = "ポイント";
  const pointText = [
    "・糖尿病内科や内視鏡内科などの専門性を打ち出す",
    <br />,
    "・在宅患者様への対応",
    <br />,
    "・入院や精密検査を紹介する病院との連携",
    <br />,
    "・当日の検査や手術での治療",
    <br />,
    "・広い駐車場や駐輪場の確保",
    <br />,
    "・夜間や休日での診察",
    <br />,
    "・市民健診の実施",
    <br />,
    <br />,
    "物件選定にあたっては近隣医療機関の情報を入手し、",
    <br />,
    "上記のような近隣の内科との差別化を明確にし集患することが重要です",
  ];

  return (
    <Layout>
      <Head props={subTitle} />
      <CaseStudiesTitle title={[title, subTitle]} />
      <CaseStudiesText
        textData={[overview, openingCash, openingCashText, point, pointText]}
      />
    </Layout>
  );
}
